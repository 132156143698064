import React from "react";
import styled from "styled-components";

export default function WeeklyPlannerHeader({ header, arbetesled }) {
  return <Container>{header}</Container>;
}
const Container = styled.div`
  background-color: grey;
  height: 5vh;
  border: solid;
  display: flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: whitesmoke;
  font-weight: 800;
`;
