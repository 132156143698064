import React from "react";
import styled from "styled-components";

export default function WeeklyPlannerContainer({ text }) {
  return (
    <Container>
      <span>{text}</span>
    </Container>
  );
}

const Container = styled.div`
  background-color: grey;
  height: 10vh;
  border: solid;
  display: flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: whitesmoke;
  font-weight: 800;
`;
