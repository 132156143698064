import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";

import styled from "styled-components";

import DaySettingsComponent from "../ShiftSettingsComponent";
import { useSettingsContext } from "../../context/SettingsProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateSelectMenu } from "../../utility/generateSelectMenu";
import ColorPickerComponent from "../ColorPickerComponent";
import axios from "../../api/axios";

export default function SettingsPageComponent() {
  const { settingsList, setSettingsList, setSelectOptions } =
    useSettingsContext();
  const axiosPrivate = useAxiosPrivate();
  const [addNewColor, setAddNewColor] = useState();
  const [category, setCategory] = useState("");
  const [department, setDepartment] = useState(
    localStorage.getItem("department") || "logistics"
  );

  const fetchSettingsData = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(`${department}/settings`, {
        signal: controller.signal,
      });
      isMounted && setSettingsList(response.data);
      setSelectOptions(generateSelectMenu(response.data));
      localStorage.setItem("department", department);
    } catch (err) {
      console.error(err);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const addColor = () => {
    const action = "addColor";
    axiosPrivate
      .patch(
        `${department}/settings`,
        { addNewColor, category, action },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        console.log(response);
        setSettingsList((prevList) => [response.data, ...prevList]);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  const translateHeader = (input) => {
    const swedish = input;
    return input === "logistics"
      ? "Logistik Inställningar"
      : swedish + " Inställningar";
  };
  useEffect(() => {
    fetchSettingsData();
  }, [department]);

  return (
    <OuterWrapper>
      <HeaderComponent header={translateHeader(department)}></HeaderComponent>
      <Container>
        <SettingsWrapper>
          <AddNewContainer>
            <div>
              <h2>Visa Inställingar:</h2>
              <select
                name="showSettings"
                onChange={(e) => {
                  localStorage.setItem("department", e.target.value);
                  setDepartment(e.target.value);
                  
                }}
              >
                <option value="logistics" hidden>
                  ---välj---
                </option>
                <option value="logistics">Logistik</option>
                <option value="transport">Transport</option>
              </select>
              <p>* Här kan du ändra vilka inställningar som visas.</p>
            </div>
          </AddNewContainer>
          <AddNewContainer>
            <h2>Lägg till ny färggrupp:</h2>
            <SettingsWrapper className="flexDirectionColumn">
              <label htmlFor="category">Kategori: </label>
              <input
                className="categoryInput"
                type="text"
                id="category"
                name="category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              ></input>
              <p>
                * Här kan du ställa in en kategori för en grupp av arbetspass så att de är lättare att hitta vid byte.
              </p>
            </SettingsWrapper>
            <h2>Välj ett färg:</h2>
            <ColorPickerComponent
              inputColor={"whitesmoke"}
              onChange={setAddNewColor}
            ></ColorPickerComponent>
            <button className={"primaryButton"} onClick={() => addColor()}>
              Lägg till
            </button>
          </AddNewContainer>
        </SettingsWrapper>
        {settingsList &&
          settingsList.map((item) => (
            <DaySettingsComponent
              shiftsOfColor={item}
              key={item._id}
              department={department}
            ></DaySettingsComponent>
          ))}
      </Container>
    </OuterWrapper>
  );
}
const Container = styled.div`
  margin-top: 1vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-row-gap: 15px;
  font-size: 1.1em;
`;

const OuterWrapper = styled.div`
  width: 100vw;
  display: block;
  background-color: rgb(102, 110, 121);
  h2 {
    min-width: 300px;
  }
  .primaryButton {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff; /* White text color by default */
    background-color: #007bff; /* Blue background color by default */
    border: none;
    border-radius: 5px;
    margin: 5px;
    margin-top: 10px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s; /* Transition both color and background-color */
    min-height: 44.2px;
    &:hover {
      background-color: #0056b3; /* Darker blue background color on hover */
    }

    &:focus {
      outline: none; /* Remove outline on focus for better visual appearance */
    }
  }
`;
const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-start: 2;

  .categoryInput {
    padding: 10px 10px;
    margin-right: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 16px;
    outline: none; /* Remove default outline */
    cursor: pointer; /* Show pointer cursor on hover */
    min-width: 25px;
  }
  label {
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .flexDirectionColumn {
    flex-direction: Column;
  }
`;
const AddNewContainer = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  grid-column-start: 2;
  background-color: #b0c4de;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  margin: 8px;

  span {
    font-weight: 600;
    margin-bottom: 10px;
  }

  select {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    width: 100%; /* Full width for better alignment */
    max-width: 200px; /* Maximum width for better layout on large screens */
  }

  @media (max-width: 600px) {
    padding: 15px;
    span {
      font-size: 14px;
    }
    select {
      font-size: 14px;
      padding: 8px;
    }
  }
`;
