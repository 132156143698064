import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import HeaderComponent from "../HeaderComponent";

const UserSelect = ({
  user,
  logistikOptions,
  transportOptions,
  selectedKey,
  onSelectionChange,
}) => {
  let value = selectedKey;
  // const handleSelectionChange = (event) => {
  //   const selectedKey = event.target.value;
  //   onSelectionChange(name, selectedKey);
  // };

  return (
    <select
      value={value}
      onChange={(e) => {
        value = e.target.value;
        onSelectionChange({
          dataKeyUpdate: { user: user, dataKey: e.target.value },
        });
      }}
      key={selectedKey}
      // id={name + "select"}
    >
      <option value="" hidden>
        Select...
      </option>
      <option value="">ingen</option>
      <optgroup label="Logistik">{logistikOptions}</optgroup>
      <optgroup label="Transport">{transportOptions}</optgroup>
    </select>
  );
};

function AccountAdminPageComponent() {
  const [userList, setUserList] = useState([]);
  const [transportKeys, setTransportKeys] = useState([]);
  const [logisticKeys, setLogisticKeys] = useState([]);
  const [userSelections, setUserSelections] = useState([]);
  const [currentSelect, setCurrentSelect] = useState({});
  // const [loading, setLoading]=useState(true)
  const axiosPrivate = useAxiosPrivate();
  const fetchData = async () => {
    const response = await axiosPrivate
      .get("/employees", {
        headers: { "Context-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        setUserList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDataKeys = async () => {
    await axiosPrivate
      .get("/datakeys", {
        headers: { "Context-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        const data = response.data;
        setTransportKeys(data["transport"].slice(1));
        setLogisticKeys(data["logistik"].slice(1));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = async (id) => {
    await axiosPrivate
      .delete(`/employees/${id}`, {
        headers: { "Context-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        const removeUser = userList.filter(
          (item) => item.id !== response.data.user
        );
        setUserList(removeUser);
      })
      .catch((err) => console.error(err));
  };
  const transportOptions = transportKeys.map((item) => {
    return <option value={item}>{item}</option>;
  });
  const logistikOptions = logisticKeys.map((item) => {
    return <option value={item}>{item}</option>;
  });

  useEffect(() => {
    fetchData();
    getDataKeys();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userSelections);
  };
  const handleSelect = async (update) => {
    await axiosPrivate
      .put("/employees", update, {
        headers: { "Context-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const withDataKeys = userList.filter((item) => item.dataKey);

  const withoutDataKeys = userList.filter(
    (item) => item.dataKey === "" || !item.dataKey
  );
  function renderAccountCard(filteredList) {
    return filteredList.map((item) => {
      const selectedValue = userSelections.filter(
        (items) => items.user === item.username
      );
      return (
        
          <Card key={item.username}>
            <CenterBlockElement>
              <CardItem>
                <Label>Användarnamn:</Label>
                {item.username}
              </CardItem>
              <CardItem>
                <Label>Namn:</Label>
                {item.name}
              </CardItem>

              <CardItem>
                <Label name={item.name + "select"}>DataNyckel:</Label>
                <UserSelect
                  key={item.username + item.dataKey}
                  user={item.username}
                  onSelectionChange={handleSelect}
                  selectedKey={
                    item.dataKey ? item.dataKey : selectedValue.dataKey
                  }
                  logistikOptions={logistikOptions}
                  transportOptions={transportOptions}
                  //onSelectionChange={handleUserSelectionChange}
                  //selectedKey={userSelections[item.username]}
                ></UserSelect>
              </CardItem>
              <CardItem>
                <Label>Admin</Label>
                <input
                  type="checkbox"
                  checked={item.roles.Admin && item.roles.Admin === 5051}
                  onChange={(e) => {
                    handleSelect({
                      adminUpdate: {
                        user: item.username,
                        applyAdmin: e.target.checked,
                      },
                    });
                  }}
                ></input>
              </CardItem>
              <CardItem>
                <button onClick={() => handleDelete(item.id)}>Radera</button>
              </CardItem>
            </CenterBlockElement>
          </Card>
        
      );
    });
  }
  return (
    <>
        <HeaderComponent
          disableImport={true}
          disableSSE={true}
          header={"Konto Inställningar"}
          />
      <Container>
        <form onSubmit={handleSubmit}>
          {/* <SubmitButton type="submit" disabled={userSelections.length === 0 ? true : false}>Update {`(${userSelections.length} st)` }</SubmitButton> */}

          <h2>Utan Datanyckel</h2>
          {userList && renderAccountCard(withoutDataKeys)}
          <h2>Med Datanyckel:</h2>
          {userList && renderAccountCard(withDataKeys)}
        </form>
      </Container>
          </>
    
  );
}

export default AccountAdminPageComponent;

const Container = styled.div`
  @media (max-width: 600px) {
    background-color: inherit;
  }
  background-color: whitesmoke;
  display: block;
  width: 100%;
  height: 100%;

  justify-content: center;
  margin: 0 auto;
  text-align: center;
  h2 {
    background-color: rgb(6, 84, 59);
    color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
  }
`;
const SubmitButton = styled.button`
  width: 100%;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 600;
`;
const Card = styled.div`
height: 100%;
background-color: whitesmoke;
@media (max-width: 600px) {
    display: inline-block;
    margin: 10px;
    width: 80%;
    border-radius: 5px;
  }
`;
const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  button {
    width: 100px;
    align-self: center;
    margin: 20px;
    transform: scale(1.5);
  }
  form{
  
  }
  span {
    align-self: center;
  }
  @media (max-width: 600px) {
    select {
      align-self: center;
      width: 100px;
      transform: scale(1.5);
      margin: 10px;
    }
    input[type="checkbox"] {
      margin: 20px;
      transform: scale(3);
      align-self: center;
    }
    padding: 5px;
  }
`;
const Label = styled.span`
  font-weight: 600;
  margin-right: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
`;

const CenterBlockElement = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  @media (max-width: 600px) {
    display: inline-block;
  }
`;
