import React from "react";
import styled from "styled-components";
import PublishIcon from "@mui/icons-material/Publish";
function UploadIconComponent({addPadding}) {

  return (
    <AlternateIcon addPadding={addPadding}>
      <Container>
        <PublishIcon sx={{ fontSize: 30, color: "whitesmoke" }}></PublishIcon>
      </Container>
    </AlternateIcon>
  );
}

const AlternateIcon = styled.div`
  position: absolute;
  display: flex;
  top: ${(props) => (props.addPadding ? "16px" : "-4px")};
  right: ${(props) => (props.addPadding ? "39px" : "0px")};
  padding-top: 5px;
  display: flex;

  @media (max-width: 700px) {
    display: none;
  }
`;
const Container = styled.button`
  width: 100%;
  background-color: rgb(49, 49, 49);
  border: none;
`;

export default UploadIconComponent;
