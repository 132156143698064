import { createContext, useContext, useState } from "react";
export const SettingsContext = createContext([]);

export const SettingsContextProvider = ({ children }) => {
  const [settingsList, setSettingsList] = useState(null);
  const [selectOptions, setSelectOptions] = useState();

  return (
    <SettingsContext.Provider
      value={{ settingsList, setSettingsList, setSelectOptions, selectOptions }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  return useContext(SettingsContext);
};
// Generate the optgroup list on page load

// Export the optgroup list
