import useAuth from "./useAuth";
import axios from "../api/axios";

function useRefreshToken() {
  const { setAuth } = useAuth();
    const refreshController = new AbortController();
    const refresh = async () => {
      
    const response = await axios
        .get("/refresh", {
          signal: refreshController.signal,
          withCredentials: true,
      })
      .catch(function (error) {
        if (error?.response) {
            if (error.response.status === 403) {
                refreshController.abort();
          }
        }
      });

  
      setAuth((prev) => {
        return {
          ...prev,
          accessToken: response?.data?.accessToken, // took away fallback
          roles: response?.data.roles, // took away fallback
          user: response?.data.user
        };
      });
  
      return response?.data?.accessToken;
  
  };

  return refresh;
}

export default useRefreshToken;
