import { Outlet } from "react-router-dom";
import { SettingsContextProvider } from "../context/SettingsProvider";

const Layout = () => {
  return (
    <SettingsContextProvider>
      <main className="App">
        <Outlet />
      </main>
    </SettingsContextProvider>
  );
};

export default Layout;
