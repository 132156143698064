import React, { useState } from "react";
import WeekComponent from "./WeekComponent";
import styled from "styled-components";
import WeekMonth from "./WeekMonth";
import { parseISO, getISOWeek } from "date-fns";

export default function DynamicViewComponent({
  name,
  sixWeekData,
  shiftOptions,
  viewStart,
  viewEnd,
  viewLength,
  containerHeight,
  changeTextSize,
  disableEdit,
  useData,
  url,
  includeMonth,
}) {
  function getGrid(viewEnd, viewStart, viewLength) {
    return `repeat(${viewEnd - viewStart}, ${viewLength - 0.5}fr)`;
  }
  function renderEmployeeName(name) {
    const upperCaseName = name;
    if (name !== "") {
      return <EmployeeName>{upperCaseName.toUpperCase()}</EmployeeName>;
    } else {
      return (
        <EmptyDiv>
          <span></span>
        </EmptyDiv>
      );
    }
  }
  function renderMonthAndWeek(date) {
    const dateString = date;
    const dateObject = parseISO(dateString);
    const swedishMonths = [
      "januari",
      "februari",
      "mars",
      "april",
      "maj",
      "juni",
      "juli",
      "augusti",
      "september",
      "oktober",
      "november",
      "december",
    ];
    const month = dateObject.getMonth();
    const week = getISOWeek(dateObject);
    return `${swedishMonths[month]}: v.${week}`;
  }

  return (
    <>
      <OuterWrapper>
        {renderEmployeeName(name)}
        <Container grid={getGrid(viewEnd, viewStart, viewLength)}>
          {sixWeekData &&
            sixWeekData.map((item, index) => {
              if (index < viewEnd && index >= viewStart) {
                return (
                  <InnerWrapper key={"wrapper" + index}>
                    {includeMonth && (
                      <WeekMonth
                        text={`${renderMonthAndWeek(item.shifts[0].date)}`}
                      ></WeekMonth>
                    )}
                    <WeekComponent
                      disableEdit={disableEdit}
                      changeTextSize={changeTextSize}
                      employee={name}
                      key={`Week+${index}`}
                      weekData={item.shifts}
                      shiftOptions={shiftOptions}
                      containerHeight={containerHeight}
                      useData={useData}
                      url={url}
                    ></WeekComponent>
                  </InnerWrapper>
                );
              }
            })}
        </Container>
      </OuterWrapper>
    </>
  );
}
const OuterWrapper = styled.div`
  width: 99%;
  display: flex;
  text-align: center;
  align-items: center;
`;
const Container = styled.div`
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  margin-left: 0.2vw;
  grid-column-gap: 0.5vh;
`;
const EmployeeName = styled.div`
  background-color: #ffff99;
  //font-size: 1em;
  text-align: right;
  height: 100%;
  font-weight: 600;
  min-width: 120px;
  min-height: fit-content;
  border-radius: 15px 0px 0px 15px;
  padding-right: 4px;
  margin-top: 3px;
`;

const EmptyDiv = styled.div`
  font-size: 1em;
  text-align: right;
  padding-right: 4px;
  height: 100%;
  font-weight: 600;
  min-width: 120px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
