export function overrideColor(date) {
    const currentDate = new Date(date);
    const todaysDate = new Date(Date.now());

    if (
      currentDate.getYear() === todaysDate.getYear() &&
      todaysDate.getMonth() === currentDate.getMonth() &&
      currentDate.getDate() === todaysDate.getDate()
    ) {
      return "#06ae0e";
    } else {
      return undefined;
    }
  }