import React from "react";
import styled from "styled-components";
import HeaderComponent from "../HeaderComponent";
import WeeklyPlannerContainer from "../WeeklyPlannerContainer";
import WeeklyPlannerHeader from "../WeeklyPlannerHeader";

export default function WeeklyPlannerPage() {
  return (
    <PlannerWrapper>
      <HeaderComponent header={"Weekly Planner"}></HeaderComponent>
      <Container>
        <WeeklyPlannerHeader header={""}></WeeklyPlannerHeader>
        <WeeklyPlannerHeader header={"Monday"}></WeeklyPlannerHeader>
        <WeeklyPlannerHeader header={"Tuesday"}></WeeklyPlannerHeader>
        <WeeklyPlannerHeader header={"Wednesday"}></WeeklyPlannerHeader>
        <WeeklyPlannerHeader header={"Thursday"}></WeeklyPlannerHeader>
        <WeeklyPlannerHeader header={"Friday"}></WeeklyPlannerHeader>

        <WeeklyPlannerContainer text={"Date"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"JIT"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"GODS"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"POST"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"LASTBIL/VÄTSKEVAGN"}>
          {" "}
        </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"APOTEK/RISK"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"SOPOR DAG/ KVÄLL/ ÅTERVINN"}>
          {" "}
        </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>

        <WeeklyPlannerContainer text={"VÄTSKEVAGNAR"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Monday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Tuesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Wednesday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Thursday"}> </WeeklyPlannerContainer>
        <WeeklyPlannerContainer text={"Friday"}> </WeeklyPlannerContainer>
      </Container>
    </PlannerWrapper>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
`;
const PlannerWrapper = styled.div`
  width: 100%;
`;
