import { createContext, useContext, useState } from "react";

export const UpdateContext = createContext([]);

export const UpdateContextProvider = ({ children }) => {
  const [updateLog, setUpdateLog] = useState([]);

  return (
    <UpdateContext.Provider value={{ updateLog, setUpdateLog }}>
      {children}
    </UpdateContext.Provider>
  );
};

export const useUpdateContext = () => {
    return useContext(UpdateContext)
}
