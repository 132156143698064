import React, { useState } from "react";
import styled from "styled-components";
import { axiosPrivate } from "../api/axios";
import { useDataContext } from "../context/DataProvider";
//import CustomSelectComponent from "./CustomSelectComponent";
import { useSettingsContext } from "../context/SettingsProvider";
import { nanoid } from "nanoid";
import { useUpdateContext } from "../context/UpdateProvider";
import useAuth from "../hooks/useAuth";

export default function DayComponent({
  employee,
  date,
  shift,
  dayIndex,
  containerHeight,
  changeTextSize,
  disableEdit,
  dayOfWeek,
  changeFontWeight,
  url,
  containerWidth,
  backgroundColorOverride,
}) {
  const auth = useAuth();
  const currentUser = auth.auth.user;
  const [shiftChange, setShiftChange] = useState("");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(disableEdit || false);
  const { dataList, setDataList } = useDataContext();
  const { settingsList, selectOptions } = useSettingsContext();
  const { setUpdateLog } = useUpdateContext();
  function handleEditToggle() {
    if (disabled === false) {
      open ? setOpen(!open) : setOpen(true);
    }
  }

  const getDate = (date) => {
    let renderDate = new Date(date);
    renderDate.setUTCHours(6);
    return renderDate.toDateString();
  };

  function changeShift(e) {
    handleEditToggle();
    handleUpdate(e);
  }
  function getColor(currentShift, dayIndex) {
    const defaultColorFunction = (day) => {
      if (day === 0 || day === 6) {
        return "#FFFF99";
      }
      return "whitesmoke";
    };
    let defaultColor;
    (dayIndex === 5) | (dayIndex === 6)
      ? (defaultColor = "#FFFF99")
      : (defaultColor = defaultColorFunction(dayOfWeek));
    let plusOrMinusShift = currentShift;
    if (plusOrMinusShift.endsWith("+") | plusOrMinusShift.endsWith("-")) {
      plusOrMinusShift = plusOrMinusShift.replace(/[^a-zA-Z0-9åäöÅÄÖ]/g, "");
    }

    let color;
    settingsList &&
      settingsList.forEach((item) => {
        const result = item.shifts.filter(
          (item) => item.shift === plusOrMinusShift
        );
        if (result.length > 0) {
          color = item.color;
        }
      });
    return backgroundColorOverride
      ? backgroundColorOverride
      : color
      ? color
      : defaultColor;
  }
  function setShiftFontSize(currentShift) {
    if (currentShift?.shift) {
      let isLongText = currentShift.shift?.length > 2 ? true : false;
      let textSize = ".7vw";
      let textCheck = currentShift.shift?.length - 3;
      if (isLongText) {
        textSize = `Calc(${textSize} - ${textCheck * 2}px)`;
      }
      return textSize;
    }
    return "1em";
  }

  //searches array for changed shift
  const findChangedShift = (oldObj, newObj) => {
    for (let i = 0; i < newObj.shifts.length; i++) {
      const oldShifts = oldObj.shifts[i].shifts;
      const newShifts = newObj.shifts[i].shifts;

      for (let j = 0; j < newShifts.length; j++) {
        if (oldShifts[j].shift !== newShifts[j].shift) {
          return { parentIndex: i, childIndex: j, changedObject: newShifts[j] };
        }
      }
    }

    console.log("No changes in shifts.");
    return undefined;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let update;

    try {
      const response = await axiosPrivate.patch(
        `${url}/api`,
        { employee, shiftChange, date },
        {
          headers: {
            "Context-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setShiftChange("");
      const updatedObject = response.data;
      const index = dataList.findIndex(
        (item) => item._id === updatedObject._id
      );
      if (index !== -1) {
        const newDataList = [...dataList];
        const changedShift = findChangedShift(
          newDataList[index],
          updatedObject
        );
        if (!changedShift) {
          // Exit the function if no changes in shifts
          console.log("No changes in shifts.");
          return;
        }
        let newUpdate = false;

        setDataList(() => {
          if (changedShift) {
            newUpdate = true;
            const currentDate = new Date(Date.now());
            const prevShift =
              newDataList[index].shifts[changedShift.parentIndex].shifts[
                changedShift.childIndex
              ];
            update = {
              _id: prevShift._id,
              name: newDataList[index].name,
              prevShift: prevShift.shift,
              newShift: changedShift.changedObject.shift,
              date: changedShift.changedObject.date,
              lastUpdate: currentDate,
            };
            //if (url === "logistics")
            //setUpdateLog((prevData) => [...prevData, update]);
            newDataList[index] = updatedObject;
            return newDataList;
          }

          //if (newUpdate) return dataList;
        });
        if (newUpdate) {
          const response2 = await axiosPrivate.post(
            `${url}/api/update`,
            { update, url, currentUser },
            {
              headers: {
                "Context-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          console.log(response2.data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Container>
      <EmptySpan onClick={handleEditToggle} containerWidth={containerWidth}>
        <ShiftContainer
          inputColor={getColor(`${shift}`, dayIndex)}
          containerHeight={containerHeight}
        >
          <StyledButton
            inputColor={getColor(`${shift}`, dayIndex)}
            textSize={setShiftFontSize({ shift })}
            changeTextSize={changeTextSize}
            changeFontWeight={changeFontWeight}
            
          >
            {shift === "0.2" ? "20%" : shift}
          </StyledButton>
        </ShiftContainer>
      </EmptySpan>

      <EditWindow
        open={open}
        inputColor={getColor(`${shift}`, settingsList)}
        disabled
      >
        <EditWindowContents inputColor={getColor(`${shift}`, settingsList)}>
          <button onClick={handleEditToggle}>CLOSE</button>
          <Employee>{employee?.toUpperCase()}</Employee>
          <Heading>{date ? getDate(date) : "Edit"}</Heading>
          <p>Current shift: {shift}</p>

          <EditShift
            id={nanoid()}
            value={shiftChange}
            onChange={(e) => setShiftChange(e.target.value)}
          >
            <option value="">Choose Shift</option>
            {selectOptions}
          </EditShift>
          {/* <CustomSelectComponent
            className="custom-select" // Apply the custom-select class
            value={shiftChange}
            onChange={(e) => setShiftChange(e.target.value)}
            shiftOptions={shiftOptions}
          /> */}
          <br />
          <br />
          <button onClick={changeShift}>CHANGE SHIFT</button>
        </EditWindowContents>
      </EditWindow>
    </Container>
  );
}

const Container = styled.div``;
const ShiftContainer = styled.div`
  background-color: ${(props) =>
    props.backgroundColorOverride
      ? props.backgroundColorOverride
      : props.inputColor};
  font-weight: 800;
  height: ${(props) => props.containerHeight};
  width: ${(props) => props.containerWidth};
  min-height: 100%;
`;
const EditShift = styled.select`
  text-align: center;
  font-weight: 600;
  width: 75%;
  max-width: 200px;
  height: 10%;
`;
const EditWindowContents = styled.div`
  background-color: ${(props) => props.inputColor || "whitesmoke"};
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 200px;
    //max-height: 280px;
    width: 50%;
    font-size: 1em;
  }
  min-width: 200px;
  max-width: 250px;
  max-height: 370px;
  min-height: fit-content;
  width: 10%;
  right: 50%;
  padding: 3px;
  padding-top: 0;
  color: #0b0b0b;
  font-size: 1em;
  overflow: hidden;
  transform: all 0.3s;
  button {
    font-weight: 700;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  border: 2px solid;
`;

const EditWindow = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 2px;
  border: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
`;

const Heading = styled.h4`
  margin: 0;
`;
const StyledButton = styled.button`
  @media (max-width: 700px) {
    font-size: 0.9em;
  }
  text-transform: capitalize;
  overflow: hidden;
  word-wrap: break-word;
  background: none;
  max-width: 20%;
  min-width: 100%;
  height: 100%;
  border: none;
  color: black;
  font-size: ${(props) => props.changeTextSize || props.textSize};
  padding: 0px;
  font-weight: ${(props) => props.changeFontWeight || 600};
`;

const EmptySpan = styled.div`
  overflow: hidden;
  word-wrap: break-word;
  background: none;
  max-width: 2vw;
  min-width: ${(props) => props.containerWidth || "100%"};
  height: 100%;
  border: solid;
  font-size: ${(props) => props.textSize};
  padding: 0px;
`;
const Employee = styled.p`
  font-size: 1.5em;
  font-weight: 900;
`;
