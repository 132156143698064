import React from 'react'
import styled from 'styled-components'

export default function WeekMonth({text}) {
  return (
      <WeekAndMonth>{text}</WeekAndMonth>
  )
}
const WeekAndMonth = styled.div`
display: flex;
width: 100%;
background-color: whitesmoke;
justify-content: center;
border: solid;
border-bottom: none;
border-color: black;
align-items: center;
height: 25px;
font-weight: 700;
font-size: larger;
`