import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
const LOGIN_URL = "/auth";

export default function LoginPageComponent() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState(localStorage.getItem("SchemaUser") || "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [saveUser, setSaveUser] = useState(
    !!localStorage.getItem("SchemaUser")
  );
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        { user, pwd },
        {
          headers: {
            "Context-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const decodedPayload = JSON.parse(atob(accessToken.split(".")[1]));

      const roles = decodedPayload.UserInfo.roles.filter(
        (item) => item !== null
      );
      const accessUser = decodedPayload.UserInfo.username;
      const dataKey = decodedPayload.dataKey;
      console.log({ noNullValues: roles });
      setAuth({ accessUser, accessToken, roles, dataKey });

      setUser("");
      setPwd("");

      if (roles.includes(1234)) {
        console.log({ what: roles });
        return navigate("/transport");
      }
      if (roles.includes(2234)) {
        return navigate("/");
      }
      if (roles.includes(2001)) {
        return navigate("/profile");
      }
      //navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Inget serversvar");
      } else if (err.response?.status === 400) {
        setErrMsg("Saknad användarnamn eller lösenord!");
      } else if (err.response?.status === 401) {
        setErrMsg("Fel användarnamn eller lösenord.");
      } else {
        setErrMsg("Inloggningen misslyckades");
      }
      errRef.current.focus();
    }
  };
  const handleSaveUser = (e) => {
    setSaveUser(e.target.checked);
    if (!saveUser) {
      localStorage.setItem("SchemaUser", user);
    }
    if (saveUser) {
      localStorage.removeItem("SchemaUser");
    }
  };

  return (
    <Container>
      <GridContainer>
        <LoginContainer>
          <LoginHeader>Logistik Schema</LoginHeader>

         

          <LoginForm type="submit" onSubmit={handleSubmit}>
            <label htmlFor="username">Användarnamn:</label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => {
                setUser(e.target.value);
                if (saveUser) {
                  localStorage.setItem("SchemaUser", e.target.value);
                }
              }}
              value={user}
              required
              maxLength={40}
            ></input>
            <label htmlFor="password">Lösenord:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              maxLength={40}
            ></input>
             <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
            <button type="submit">Logga in</button>
          </LoginForm>
          <RememberUserContainer>
            <input
              type="checkbox"
              id="saveUser"
              onChange={(e) => handleSaveUser(e)}
              checked={saveUser}
            />

            <label htmlFor="saveUser">Kom ihåg mig</label>
          </RememberUserContainer>
          <CreateAccount>
            <a href="/" hidden>
              Create Account
            </a>
          </CreateAccount>
        </LoginContainer>
      </GridContainer>
    </Container>
  );
}
const RememberUserContainer = styled.div`
  display: flex;
  padding: 10px 10px 0px 10px;
  justify-content: center;
  input {
    transform: scale(2);
    margin-left: -30px;
    margin-top: 20px;
  }
  label {
    transform: scale(1.5);
    padding-left: 30px;
    margin-top: 15px;
  }
`;
const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  background-color: rgb(6, 84, 59);
  position: relative;
`;
export const InnerWrapper = styled.div`
  /* display: grid;
grid-template-columns: 3fr 4fr 3fr;
grid-template-rows: 3fr 4fr 3fr;
grid-column-start: 2; */
  /* @media(max-width:840px){
  grid-template-rows: 2fr 4fr 3fr;
  grid-template-columns: 12fr;
} */
`;
export const GridContainer = styled.div`
  /* @media (max-width: 350px) {
    top: 15%;
    right: Calc(50% - 145px);
  }
  position: absolute;
  top: 25%;
  right: Calc(50% - 165px); */
  display: flex;
  grid-row-start: 2;
  grid-column-start: 2;
  justify-content: center;
  align-items: center;
`;
export const LoginContainer = styled.div`
  border-radius: 15px;
  /* max-width: 430px;
  max-height: 400px; */
  position: relative;
  display: grid;
  border: 5px solid;
  text-align: center;
  padding: 40px;
  background-color: whitesmoke;
  p {
    font-size: 1.2em;
    margin-top: 20px;
    min-height: 27px;
    color: red;
    margin-bottom: 0;
  }
`;
export const CreateAccount = styled.span`
  font-weight: 700;
  font-size: small;
  margin: 0px;
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
  label {

    align-self: flex-start;
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }
  button {
    color: rgb(6, 84, 59);
    background: whitesmoke;

    font-size: 1.5em;
    font-weight: 600;
    margin-top: 10%;
    border-radius: 6px;
    font-size: 30px;
    line-height: 48px;
    padding: 0 16px;
    width: 332px;
  }
  input {
    font-weight: 500;
      max-width:85%;
      padding: 12px 16px;
      margin-bottom: 10px;
      border: 2px solid black;
      border-radius: 5px;
      font-size: 16px;
    
    padding: 14px 16px;
    border-radius: 6px;
  }
`;
export const LoginHeader = styled.h1`
  padding: 10% 0px;
  margin: 0px;
  color: ${(props) => props.color || "black"};
  text-align: center;
  font-size: 2.5em;
  @media (max-width: 350px) {
    font-size: 1.5em;
  }
`;
