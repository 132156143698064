import React, { useState } from "react";
import styled from "styled-components";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import NavigationComponent from "./NavigationComponent";
import UploadIconComponent from "./UploadIconComponent";
import toast, { Toaster } from "react-hot-toast";

export default function HeaderComponent({
  disableImport,
  header,
  url,
  addPadding,
  disableSSE,
}) {
  const [uploadFile, setUploadFile] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const UPLOAD_URL = `${url}/upload`;
  const [toggle, setToggle] = useState(
    localStorage.getItem("eventStream") || false
  );
  const handleClick = (e) => {
    e.preventDefault();
    setOpenForm((prev) => !prev);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("startDate", startDate);
    try {
      await axiosPrivate
        .post(UPLOAD_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
          mode: "cors",
        })
        .then((response) => {
          console.log("Upload successful:", response.data);
          toast.success("Fil Uppladdad!");
          setTimeout(reloadWindow, 2000);
        })
        .then()
        .catch((error) => {
          console.error("Upload failed:", error);
          toast.error("Inträffade ett fel, dubbel kolla data och försök igen.");
        });
    } catch (err) {
      console.error(err);
      toast.error("Inträffade ett fel, dubbel kolla data och försök igen.");
    }
    function reloadWindow() {
      window.location.reload();
    }
    // setTimeout(reloadWindow, 2000);
  };
  function setLocalStorage(toggle) {
    toggle
      ? localStorage.setItem("eventStream", "true")
      : localStorage.removeItem("eventStream");
  }
  return (
    <header>
      <ScheduleWrapper>
        <HeaderWrapper>
          <NavigationComponent disable={disableImport}></NavigationComponent>
          <HeaderGrid>
            <HeaderContainer>{header}</HeaderContainer>
          </HeaderGrid>
        </HeaderWrapper>
        <EventStreamToggle
          disable={disableSSE}
          addPadding={addPadding}
          toggleOn={toggle}
          onClick={() => {
            //setEventStream(!eventStream);
            setLocalStorage(!toggle);
            setToggle(!toggle);
            window.location.reload();
          }}
        ></EventStreamToggle>
        <UploadButton onClick={handleClick} disable={disableImport}>
          <UploadIconComponent addPadding={addPadding} />
        </UploadButton>

        {openForm && (
          <UploadMenu>
            <h3>Upload Data:</h3>
            <form type="submit" onSubmit={handleSubmit}>
              <label htmlFor="startDate">Enter Start Date:</label>
              <input
                type="date"
                id="startDate"
                onChange={(e) => setStartDate(e.target.value)}
                maxLength={20}
              ></input>
              <label htmlFor="uploadFile">Choose File:</label>
              <input
                type="file"
                id="uploadFile"
                name="file"
                onChange={(e) => setUploadFile(e.target.files[0])}
              ></input>
              <button>Submit</button>
            </form>
          </UploadMenu>
        )}
      </ScheduleWrapper>
      <Toaster position="bottom-center" />
    </header>
  );
}
const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const EventStreamToggle = styled.div`
  display: ${(props) => (props.disable === true ? "none" : "")};
  position: absolute;
  right: ${(props) => (props.addPadding ? "45px" : "7px")};
  top: ${(props) => (props.addPadding ? "27px" : "42px")};
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background-color: ${(props) => (props.toggleOn ? "green" : "red")};
  border: 4px solid;
`;
const ScheduleWrapper = styled.div`
  z-index: 100;
  background-color: rgb(6, 84, 59);
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;
const HeaderGrid = styled.div`
  //display: grid;
  display: flex;
  align-items: center;
  //grid-template-columns: 2fr 8fr 2fr;
  margin-left: 124;
  width: 100%;
`;
const HeaderContainer = styled.div`
  @media (max-width: 700px) {
    // margin-left: 55px;
    font-size: 1.5em;
  }
  @media (max-width: 450px) {
    //margin-left: 55px;
    font-size: 1.5em;
  }

  text-transform: capitalize;
  background-color: rgb(6, 84, 59);
  text-align: center;
  font-size: 3em;
  font-weight: 600;
  color: whitesmoke;
  font-weight: 700;
  width: 100%;
`;
const UploadButton = styled.div`
  display: ${(props) => (props.disable ? "none" : "")};
`;

const UploadMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 5px;
  font-weight: 600;
  background-color: whitesmoke;
  justify-content: center;
  border-style: solid;
  h3 {
    text-align: center;
  }
  form {
    display: grid;
    grid-template-columns: 12fr;
    padding: 10px;
    input {
      margin-bottom: 16px;
      height: 30px;
      margin-right: 40px;
      margin-left: 40px;
    }
    label {
      margin-bottom: 16px;
    }
    button {
      height: 50px;
      font-size: larger;
      font-weight: 800;
      color: whitesmoke;
      background-color: rgb(6, 84, 59);
    }
  }
`;
