import React from "react";
import styled from "styled-components";
import ListIcon from '@mui/icons-material/List';
export default function HamburgerIcon() {
  return (
    <section>
      <AlternateIcon>
        <ListIcon sx={{fontSize: 62, color: "whitesmoke"}}></ListIcon>
      </AlternateIcon>
    </section>
  );
}
const AlternateIcon = styled.div`
  width: 60px;
  height: 50px;
  display: block;
  margin-top: 10px;
`;
