import React, { useState } from "react";
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
import HamburgerIcon from "./HamburgerIcon";
export default function NavigationComponent({ disable }) {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const { auth } = useAuth();
  const handleNavToggle = () => {
    setNavBarOpen((prev) => !prev);
  };

  return (
    <Navigation className="NAVIGATION">
      <div className="iconContainer">
        <div>
          <button onClick={handleNavToggle}>
            <HamburgerIcon />
          </button>
        </div>
      </div>

      <ul className={`MainMenu ${navBarOpen ? "show_menu" : "close_menu"} `}>
        <div className="Menu btn:hover a-link:hover">
          <li>
            <a href="/login">Logga in</a>
          </li>
          <li>
            <a href="/profile">Mitt Schema</a>
          </li>
          {auth && auth.roles.includes(2234) && (
            <li>
              <a href="/">Logistik Schema</a>
            </li>
          )}
          {auth && auth.roles.includes(1234) && (
            <li>
              <a href="/transport">Transport Schema</a>
            </li>
          )}
          
          {auth &&
            auth.roles.includes(5051) &&
            !auth.roles.includes(1234) &&
            !auth.roles.includes(2234) && (
              <>
                <hr></hr>
                <li>
                  <a href="/">Logistik Schema</a>
                </li>
                <li>
                  <a href="/transport">Transport Schema</a>
                </li>
              </>
            )}
          {auth && auth.roles.includes(5051) === true && (
            <>
              <hr></hr>
              Admin Zone:
              <li>
                <a href="/transport/edit"> Ändra Transport Schema</a>
              </li>
              <li>
                <a href="/logistik/edit">Ändra Logistik Schema</a>
              </li>
              <li>
                <a href="/settings">Inställningar</a>
              </li>
              <hr></hr>
              Test Sidor:
              <li>
                <a href="/weeklyplanner">Vem och Vad</a>
              </li>
              <li>
                <a href="/createUser">Skapa Konto</a>
              </li>
              <li>
                <a href="/admin">Admin Panel</a>
              </li>
            </>
          )}
        </div>
      </ul>
    </Navigation>
  );
}

const Navigation = styled.div`
  position: relative;
  width: 5%;
  align-self: left;
  display: ${(props) => (props.disable ? "none" : "")};
  min-width: 123.9px;
  max-width: 123.9px;
  .iconContainer {
    border-radius: 0;
    background-color: rgb(49, 49, 49);
    display: inline-flex;
    

    button {
      background: none;
      border-style: round;
      border: none;
      cursor: pointer;
    }
  }

  ul {
    text-align: center;
    background-color: #cd8707;
    li {
      align-self: center;
      padding: 10px;
      font-size: 1.5em;
      list-style: none;
      font-weight: 600;
      a:link,
      a:visited {
        color: #f7f4f4;
        display: inline-block;
        text-decoration: none;
      }
    }

    &.MainMenu {
      position: absolute;
      border-style: solid;
      border-radius: 0 15px 15px 15px;
      list-style: none;
      background-color: none;
      color: whitesmoke;
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      max-width: 415px;
      min-width: 300px;
      font-size: 1em;
      a:hover {
        color: rgb(49, 49, 49);
        font-weight: 700;
      }
      .SubMenu {
        translate: initial;
        font-size: 1vw;
        color: whitesmoke;
        font-weight: 500;
      }

      &.show_menu {
        z-index: 200;
        li {
          button {
            border: none;
            background: none;
            color: whitesmoke;
            font-size: 1em;
            cursor: pointer;
          }
          button:hover {
            color: whitesmoke;
            font-weight: 700;
          }
        }
      }
      &.close_menu {
        display: none;
        height: 0%;
        width: 0%;
      }
    }
  }
  .move_down {
  }

  .apply_transition {
    animation: growDown 300ms ease-in-out;
  }
`;
