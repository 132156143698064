import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LoginContainer, LoginForm, LoginHeader } from "./LoginPageComponent";
import HeaderComponent from "../HeaderComponent";
function CreateAccountPage() {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [name, setName] = useState("");
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const handleSubmit = async (e) => {
    const response = axiosPrivate
      .post(
        "/register",
        { user, pwd, name },
        {
          headers: { "Context-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        // Handle successful response
        if (response.status === 201) {
          setUser("");
          setPwd("");
          setName("");
          toast.success(`Success: ${user} account created!`);
          setErrMsg("")
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          setErrMsg("Användarnamnet används redan.");
          toast.error("Användarnamnet används redan.");
        }
        if (!error.response.status) {
          setErrMsg("Inget serversvar");
          toast.error("Inget serversvar");
        } else if (error.response.status === 400) {
          setErrMsg("Användarnamn eller lösenord saknas!");
          toast.error("Användarnamn eller lösenord saknas!");
        } else if (error.response.status === 401) {
          setErrMsg("Obehörig!");
          toast.error("Obehörig!");
        }
        errRef.current.focus();
      });
  };

  return (
    <Container>
      <HeaderComponent header={``} disableImport={true} disableSSE={true} />
      <CenterDiv>
        <Card>
          <LoginHeader>Skapa Konto</LoginHeader>
          <form type="submit">
            <div>
              <label htmlFor="name">Namn eller HSAID:</label>
              <input
                type="text"
                id="user"
                autoComplete="new-password"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                maxLength={40}
                required
              ></input>
              <label htmlFor="username">Användarnamn:</label>
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="new-password"
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                value={user}
                maxLength={40}
                required
              ></input>
              <label htmlFor="password">Lösenord:</label>
              <input
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                maxLength={40}
              ></input>
              
            </div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <button type="button" onClick={handleSubmit}>
              Skapa Konto
            </button>
          </form>
        </Card>
      </CenterDiv>
      <Toaster position="bottom-center" />
    </Container>
  );
}

export default CreateAccountPage;

const Container = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(6, 84, 59);
`;
const Card = styled.div`
  margin: 20px;
  background-color: whitesmoke;
  width: 400px;
  border: 5px solid black;
  padding: 40px;
  button {
    padding: 10px;
    width: 100%;
  }
  label {
    align-self: flex-start;
    padding: 0px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      width: 250px;
      @media (max-width: 500px) {
        width: fit-content;
        
      }
    }
    input {
      font-weight: 500;
      max-width:85%;
      padding: 12px 16px;
      margin-bottom: 10px;
      border: 2px solid #007bff;
      border-radius: 5px;
      font-size: 16px;
      outline: none;
      cursor: pointer;
      width: 100%; /* Full width for better alignment */
      //max-width: 250px; /* Maximum width for better layout on large screens */
      @media (max-width: 500px) {
        width: 100%;
        height: 100%;
      }
    }
    button {
      color: rgb(6, 84, 59);
      font-size: 26px;
      margin: 20px 20px;
      font-weight: 600;
      width: 80%;
    }
  }
  p {
    min-height: 22px;
    color: red;
    margin: 0;
  }
  @media (max-width: 500px) {
    width:100%;
    padding: 0;
    margin: 0;
    input {
      
    }
  }
`;
const CenterDiv = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    height: 70%;
  }
`;
