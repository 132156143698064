import React from "react";
import styled from "styled-components";
import intlFormat from "date-fns/intlFormat";
import DayComponent from "./DayComponent";
import { nanoid } from "nanoid";
import { swedishMonths } from "./Pages/LogistikDisplayPageComponent";
const Update = styled.div`
  //zoom: 150%;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 5px 0;
  font-size: ${(props) => (props.smalltext ? "14px" : "1.2em")};
  .smallerText{
    font-size: ${(props) => (props.smalltext ? "10px" : "1.2em")};
  }
`;
const UpdateContainer = styled.div`
  max-height: ${(props) => (props.smalltext ? "10em" : "13.5em")};
  display: flex;
  margin: 0;
  border-radius: 20px;
  color: whitesmoke;
  //background-color: skyblue;
  //border-style: solid;
  height: 100%;
  padding: 0px 30px;
  padding-bottom: 20px;
  flex-direction: column;
  h2 {
    font-size: ${(props) => (props.smalltext ? "16px" : "20px")};
    padding-left: 10px;
    text-align: left;
    margin-bottom: 0;
  }
  .boldName {
    font-weight: 600;
  }
  .addPadding {
    font-size: ${(props) => (props.smalltext ? "14px" : "inherit")};
    padding: 0 5px;
  }
  .addLeftPadding {
    padding-left: 10px;
  }
`;
const UpdateWrapper = styled.div`
  padding-left: 10px;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0.9em;
`;

function UpdateLogComponent({ context, smalltext }) {
  return (
    <UpdateContainer smalltext={smalltext}>
      <h2 smalltext={smalltext}>Senaste Uppdateringarna:</h2>
      <UpdateWrapper>
        {context &&
          context.map((item) => {
            const itemDate = item.shiftDate;
            const renderDate = new Date(itemDate);
            const monthIndex = renderDate.getMonth();
            const day = renderDate.getDate();
            const currentDate = intlFormat(
              item.lastUpdate,
              {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              },
              { locale: "sv-SE" }
            );
            return (
              <Update key={nanoid()} smalltext={smalltext}>
                <span>{currentDate}</span>
                <span className="boldName addLeftPadding">{`${item.name}:`}</span>
                <span className="addPadding">{` arbetespass ändrats på ${swedishMonths[monthIndex]} ${day} från`}</span>
                <DayComponent
                  disableEdit={true}
                  containerWidth="45px"
                  shift={item.prevShift}
                  changeTextSize={"15px"}
                ></DayComponent>
                <span className="addPadding">till</span>
                <DayComponent
                  changeTextSize={"15px"}
                  disableEdit={true}
                  containerWidth="45px"
                  shift={item.newShift}
                ></DayComponent>
                <span className="addPadding">av {item.changedBy}</span>
              </Update>
            );
          })}
      </UpdateWrapper>
    </UpdateContainer>
  );
}

export default UpdateLogComponent;
