import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
        //setTimeout(async() => { await refresh() }, 1000);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, []);
 // return <>{isLoading && !auth?.accessToken ? <p>Loading...</p>  :<Outlet />}</>;
  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : !auth?.accessToken ? (
        <Navigate to="/login" state={{ from: location }} replace />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin