import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSettingsContext } from "../../context/SettingsProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { overrideColor } from "../../utility/overrideBackgroundColor";
import DayComponent from "../DayComponent";
import HeaderComponent from "../HeaderComponent";
export default function ProfilePageComponent() {
  //const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [profileData, setProfileData] = useState();
  const { setSettingsList, settingsList } = useSettingsContext();
  const [loading, setLoading] = useState(false);
  const [viewShift, setViewShift] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const swedishMonths = [
    "januari",
    "februari",
    "mars",
    "april",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
  ];

  useEffect(() => {
    const getEmployeeData = async () => {
      const result = await axiosPrivate
        .get("/logistics/profile", { withCredentials: true })
        .catch((err) => {
          console.error(err);
        });
      console.log(result);
      const { settings, data } = result.data;
      setProfileData(data);
      setSettingsList(settings);
    };
    console.log(settingsList);
    getEmployeeData();
  }, []);

  useEffect(() => {
    if (viewShift && settingsList) {
      setBackgroundColor(getColorSettings(viewShift).color);
    }
  }, [viewShift]);

  function toggleMenu() {
    setShowMenu((prev) => !prev);

    if (showMenu === true) {
      setViewShift("");
      setBackgroundColor("");
    }
  }
  function getColorSettings(currentShift) {
    const currentSettings = settingsList.find((item) =>
      item.shifts.some((shift) => shift.shift === currentShift)
    );
    return currentSettings;
  }
  function getShiftInfo(searchForShift) {
    let shiftSettings;
    if (searchForShift) {
      const shiftArrayContainsSetting = getColorSettings(searchForShift);

      if (shiftArrayContainsSetting && shiftArrayContainsSetting.shifts) {
        shiftSettings = shiftArrayContainsSetting.shifts.find(
          (item) => item.shift === searchForShift
        );
      }
    }

    if (shiftSettings) {
      return (
        <>
          {shiftSettings.startTime && (
            <p>Starttid: kl.{shiftSettings.startTime}</p>
          )}
          {shiftSettings.lunchTime && (
            <p>Lunch: kl.{shiftSettings.lunchTime}</p>
          )}
          {shiftSettings.endTime && <p>Sluttid: kl.{shiftSettings.endTime}</p>}
        </>
      );
    }
  }
  function startDate() {
    const date = new Date(profileData.shifts[0].shifts[0].date);
    if (date) {
      return swedishMonths[date.getMonth()];
    }
  }

  return (
    <Container>
      {profileData ? (
        <HeaderComponent
          header={`${profileData.name}'s Schema`}
          disableImport={true}
          disableSSE={true}
        />
      ) : (
        <HeaderComponent
          header={`Schema`}
          disableImport={true}
          disableSSE={true}
        />
      )}
      {
        <Overlay open={showMenu} color={backgroundColor}>
          {
            backgroundColor&&<div color={backgroundColor}>
              <h2>{viewShift}</h2>

              {settingsList && getShiftInfo(viewShift)}

              <button onClick={toggleMenu}>Stänga</button>
            </div>
          }
        </Overlay>
      }
      <ContentContainer>
        {!profileData && (
          <Nodata>
            Ingen information hittad! En administratör eller koordinator måste
            koppla ditt konto till rätt data innan användning.
          </Nodata>
        )}
        {profileData && (
          <WeekAndDataContainer>
            <DataWrapper>
              {settingsList &&
                profileData.shifts.map((weekItem, index) => {
                  const weekElement = weekItem.shifts.map((item) => {
                    const date = new Date(item.date);
                    return (
                      <DayWrapper key={nanoid()}>
                        <DayComponent
                          shift={date.getDate()}
                          disableEdit={true}
                          changeTextSize={"1em"}
                          dayOfWeek={date.getDay()}
                          changeFontWeight={600}
                          containerHeight={"25px"}
                          backgroundColorOverride={overrideColor(date)}
                        ></DayComponent>
                        <DayComponent
                          shift={item.day}
                          containerHeight={"25px"}
                          changeTextSize={"1em"}
                          disableEdit={true}
                          dayOfWeek={date.getDay()}
                          changeFontWeight={600}
                          backgroundColorOverride={overrideColor(date)}
                        ></DayComponent>
                        <CustomButton
                          onClick={() => {
                            setViewShift(item.shift);
                            if (item.shift !== "") {
                              toggleMenu();
                            }
                          }}
                        >
                          <DayComponent
                            shift={item.shift}
                            disableEdit={true}
                            containerHeight={"50px"}
                            changeTextSize={"1.5em"}
                            dayOfWeek={date.getDay()}
                            changeFontWeight={600}
                          ></DayComponent>
                        </CustomButton>
                      </DayWrapper>
                    );
                  });

                  const checkDate = new Date(weekItem.shifts[0].date);

                  return checkDate.getDate() <= 7 ? (
                    <React.Fragment key={nanoid()}>
                      <MonthWrapper>
                        <CenterMonth>
                          {swedishMonths[checkDate.getMonth()]}
                        </CenterMonth>
                      </MonthWrapper>

                      <CombinedContainer>
                        {"v." + weekItem["week"]}
                      </CombinedContainer>
                      {weekElement}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={nanoid()}>
                      <CombinedContainer>
                        {"v." + weekItem["week"]}
                      </CombinedContainer>
                      {weekElement}
                    </React.Fragment>
                  );
                })}
            </DataWrapper>
          </WeekAndDataContainer>
        )}
      </ContentContainer>
    </Container>
  );
}
const CustomButton = styled.div``;
const Overlay = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  width: 100%;
 height:95%;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  div {
    padding-bottom: 20px;
    h2 {
      text-align: center;
    }
    button {
      padding: 12px 16px;
      width: 180px;
      font-weight: 600;
    }
    display: flex;
    margin-bottom: 250px;
    background-color: ${(props) => (props.color ? props.color : "whitesmoke")};
    overflow: none;
    width: 200px;
    flex-direction: column;
    align-items: center;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
`;
const MonthWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  grid-column: span 8;

  padding: 5px;
`;
const Nodata = styled.div`
  display: flex;
  width: 100%;
  height: 75%;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`;

const DayWrapper = styled.div`
  display: block;
`;
const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;
const DataWrapper = styled.div`
  display: grid;
  //grid-column-start: 2;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  grid-row-gap: 5px;
`;

const WeekAndDataContainer = styled.div`
  display: grid;
  //grid-template-columns: 10fr 1fr;
  width: 80%;
  max-width: 1200px;
  @media (max-width: 500px) {
    width: 100%;
    //grid-template-columns: 2fr 10fr;
  }
`;

const CombinedContainer = styled.div`
  display: flex;
  background-color: whitesmoke;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
`;
const CenterMonth = styled.div`
  background-color: whitesmoke;
  font-weight: 700;
  font-size: 2em;
  align-self: center;
  padding: 5px 100px;
  border-radius: 10px;
  @media (max-width: 250px) {
    text-align: center;
    width: 100%;
    border-radius: 0;
    padding: 0;
    font-weight: 600;
  }
`;
