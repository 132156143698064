import axios from "axios";

export const BASE_URL =
"https://api.schema.mikkotirronen.com"
  //"http://localhost:3001"
  //"https://logistics-schedule-7c382e8b38ba.herokuapp.com";

export default axios.create({
  baseURL: BASE_URL,
});
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
