import { createContext, useContext, useState } from "react";

export const TransportUpdateContext = createContext([]);

export const TransportUpdateContextProvider = ({ children }) => {
  const [updateLog, setUpdateLog] = useState([]);

  return (
    <TransportUpdateContext.Provider value={{ updateLog, setUpdateLog }}>
      {children}
    </TransportUpdateContext.Provider>
  );
};

export const useTransportUpdateContext = () => {
    return useContext(TransportUpdateContext)
}
