import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import styled from "styled-components";

export default function ColorPickerComponent({ inputColor, onChange }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(true);
  const [color, setColor] = useState(inputColor);

  // const handleClick = () => {
  //   setDisplayColorPicker(!displayColorPicker);
  // };

  // const handleClose = () => {
  //   setDisplayColorPicker(false);
  // };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    onChange(newColor.hex);
  };

  const styles = reactCSS({
    default: {
      swatch: {
        padding: "0px",
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
      },
      popover: {
        // position: "absolute",
        // top: "150px",
        //zIndex: "2",
        cursor: "pointer",
        margin: "20px 20px 20px 0px",
      },
      cover: {
        // position: "fixed",
        // top: "0px",
        // right: "0px",
        // bottom: "0px",
        // left: "100px",
      },
    },
  });

  return (
    <Container>
      {displayColorPicker ? (
        <div className="" style={styles.popover}>
          <div style={styles.cover} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
      <div className="flexDirectionRow">
        <div style={styles.swatch}>
          <div
            style={{
              width: "140px",
              height: "140px",
              borderRadius: "10px",
              background: color,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="hexCode">
              <span>Färg:</span>
              <p>{color}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  align-items: start;

  .flexDirectionRow {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .hexCode {
    p {
    }
    font-size: 1em;
    span {
      padding-right: 10px;
      margin: 0;
    }
  }
`;
