import React from "react";
import DayComponent from "./DayComponent";
import styled from "styled-components";

export default function WeekComponent({
  employee,
  weekData,
  containerHeight,
  changeTextSize,
  disableEdit,
  useData,
  url
}) {
  const data = useData;
 
  return (
    <Container>
      {weekData.map((item, index) => {
        return (
          <DayComponent
            employee={employee}
            key={index}
            shift={data === "weekDay" ?
            item.day : item.shift}
            date={item.date}
            dayIndex={index}           
            containerHeight={containerHeight}
            changeTextSize={changeTextSize}
            disableEdit={disableEdit}
            url={url}
          ></DayComponent>
        );
      })}
    </Container>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: 100%;
`;
