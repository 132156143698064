import React, { useState } from "react";
import styled from "styled-components";

export default function StyledRadioButton({
  setStart,
  setEnd,
  setLength,
  setInt,
  disable,
  todaysDate,
}) {
  const [select, setSelect] = useState(false);
  const [selectThree, setSelectThree] = useState(false);
  const [selectSix, setSelectSix] = useState(false);

  function twoWeekView(date = 0) {
    setStart(date < 5 ? date : 4);
    setEnd(date < 5 ? date + 2 : 6);
    setLength(6);
    setInt(1);
  }

  function threeWeekView(date = 0) {
    setStart(date < 4 ? date : 3);
    setEnd(date < 4 ? date + 3 : 6);
    setLength(4);
    setInt(1);
  }

  function sixWeekView() {
    setStart(0);
    setEnd(6);
    setLength(2);
    setInt(6);
  }
  return (
    <StyledSection disable={disable}>
      
      <Container>
        <StyledInput
          type="radio"
          id={"twoWeeks"}
          name={"SetView"}
          checked={select}
          readOnly
        ></StyledInput>
        <span
          className={`${select ? "selectedView" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setSelect(!select);
            setSelectThree(false);
            setSelectSix(false);
            twoWeekView(todaysDate);
          }}
        >
          {"Två Veckor"}
        </span>

        <StyledInput
          className={`${disable ? "disable" : ""}`}
          type="radio"
          name={"SetView"}
          id={"threeWeeks"}
          checked={selectThree}
          readOnly
        ></StyledInput>
        <span
          className={`${
            selectThree ? "removeNextSelect selectedView" : "removeNextSelect"
          } ${disable ? "disable" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setSelectThree(!selectThree);
            setSelect(false);
            setSelectSix(false);
            threeWeekView(todaysDate);
          }}
        >
          {"Tre Veckor"}
        </span>

        <StyledInput
          className={`${disable ? "disable" : ""}`}
          type="radio"
          name={"SetView"}
          id={"sixWeeks"}
          checked={selectSix}
          readOnly
        ></StyledInput>
        <span
          className={`${
            selectSix ? "removeSelect selectedView" : "removeSelect"
          } ${disable ? "disable" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setSelectSix(!selectSix);
            setSelect(false);
            setSelectThree(false);
            sixWeekView();
          }}
        >
          {"Sex Veckor"}
        </span>
      </Container>
    </StyledSection>
  );
}
const StyledInput = styled.input`
  display: none;
`;
const StyledSection = styled.section`
  display: flex;
  margin: 0px 1em;
  align-content: center;
  font-size: 1em;
  color: whitesmoke;
  span {
    padding: 10px;
  }
  .selectedView {
    font-weight: 700;
    color: green;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;
const Container = styled.div`
  color: black;
  background-color: whitesmoke;
  border: 2px solid;
  border-radius: 5px;
  align-items: center;
  padding-bottom: 1px;
  margin-left: 5px;
  .removeNextSelect {
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .removeSelect {
    @media (max-width: 1450px) {
      display: none;
    }
  }

  @media (max-width: 700px) {
    display: none;
  }
  .disable {
    display: none;
  }
`;
