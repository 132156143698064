import { createContext, useContext, useState } from "react";

export const DataContext = createContext([]);

export const DataContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);

  return (
    <DataContext.Provider value={{ dataList, setDataList }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
    return useContext(DataContext)
}
