export function generateSelectMenu(settingsList) {
  const itemsWithCategory = [];
  const itemsWithoutCategory = [];

  settingsList.forEach((item) => {
    if (item.category) {
      itemsWithCategory.push(item);
    } else {
      itemsWithoutCategory.push(item);
    }
  });

  const uniqueCategories = new Set(
    itemsWithCategory.map((item) => item.category)
  );

  const noCategoryOptgroup = (
    <optgroup label="No Category" key="no-category">
      {itemsWithoutCategory.map((item) =>
        item.shifts.map((shift) => (
          <option key={shift._id}>{shift.shift}</option>
        ))
      )}
    </optgroup>
  );

  // Generate optgroup elements for items with categories
  const categoryOptgroups = Array.from(uniqueCategories).map((category) => {
    const itemsInCategory = itemsWithCategory.filter(
      (item) => item.category === category
    );

    const optgroup = (
      <optgroup label={category} key={category}>
        {itemsInCategory.map((item) =>
          item.shifts.map((shift) => (
            <option key={shift._id}>{shift.shift}</option>
          ))
        )}
      </optgroup>
    );

    return optgroup;
  });

  // Combine the optgroups to put "No Category" first, followed by categorized items
  const optgroupElements = [noCategoryOptgroup, ...categoryOptgroups];

  return optgroupElements;
}
