import { nanoid } from "nanoid";
import React, { useState } from "react";
import styled from "styled-components";
import axios from "../api/axios";
import { useSettingsContext } from "../context/SettingsProvider";
import DayComponent from "./DayComponent";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
export default function DaySettingsComponent({ shiftsOfColor, department }) {
  //const [selectedColor, setSelectedColor] = useState(shiftsOfColor.color);
  // const [inputValue, setInputValue] = useState("");
  // const axiosPrivate = useAxiosPrivate();
  // const oldColor = shiftsOfColor.color;
  const [shift, setShift] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [lunchTime, setLunchTime] = useState("");
  const { settingsList, setSettingsList } = useSettingsContext();
  const [useDepartment] = useState(department);
  // const handleColorChange = (newColor) => {
  //   console.log(newColor);
  //   setSelectedColor(newColor);
  // };
  const axiosPrivate = useAxiosPrivate();
  const handleDelete = (id) => {
    const shiftId = id;
    const action = "deleteShift";
    const documentId = shiftsOfColor._id;
    axiosPrivate
      .patch(
        `${useDepartment}/settings`,
        { documentId, shiftId, action },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        const updatedEntry = response.data.updatedSettings;
        setSettingsList((prevItems) =>
          prevItems.map((item) =>
            item._id === updatedEntry._id
              ? { ...item, shifts: updatedEntry.shifts }
              : item
          )
        );
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  const handleColorDelete = () => {
    const action = "deleteDocument";
    const documentId = shiftsOfColor._id;
    axiosPrivate
      .patch(
        `${useDepartment}/settings`,
        { documentId, action },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const newList = settingsList.filter(
            (item) => item._id !== documentId
          );
          setSettingsList(newList);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleAddShiftToColor = () => {
    const action = "addShift";
    const documentId = shiftsOfColor._id;

    axiosPrivate
      .patch(
        `${useDepartment}/settings`,
        { documentId, shift, action, startTime, lunchTime, endTime },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        const updatedEntry = response.data;
        setSettingsList((prevItems) =>
          prevItems.map((item) =>
            item._id === updatedEntry._id
              ? { ...item, shifts: updatedEntry.shifts }
              : item
          )
        );
      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  return (
    <InnerWrapper>
      <ColorAndListContainer>
        <StartTimeContainer>
          <CategoryLabel>
            {shiftsOfColor.category
              ? `Kategori: ${shiftsOfColor.category}`
              : "Kategori: Ingen"}
          </CategoryLabel>
          {/* <label htmlFor="startTime">Start Time:</label>
        
          */}
          <ColorLabel>Färg:</ColorLabel>

          <ColorHeader>{shiftsOfColor.color}</ColorHeader>
          <ColorSwatch color={shiftsOfColor.color}></ColorSwatch>
          <DeleteButton
            onClick={() => {
              handleColorDelete();
            }}
          >
            Radera Färggrupp
          </DeleteButton>
        </StartTimeContainer>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>Skift</th>
                <th>Starttid</th>
                <th>Sluttid</th>
                <th>Lunch</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shiftsOfColor.shifts.map((item) => {
                return (
                  <tr key={nanoid()}>
                    <td>
                      <ShiftContainer key={nanoid()}>
                        <DayComponent
                          shift={item.shift}
                          changeTextSize={"1em"}
                          disableEdit={true}
                          containerWidth={"120px"}
                        ></DayComponent>
                      </ShiftContainer>
                    </td>
                    <td>{item.startTime}</td>
                    <td>{item.endTime}</td>
                    <td>{item.lunchTime}</td>

                    <td>
                      <DeleteButton onClick={() => handleDelete(item._id)}>
                        {"Radera"}
                      </DeleteButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </CustomTable>
        </TableContainer>

        <AddShiftContainer>
          <InputContainter>
            <label htmlFor="shift">Skift:</label>
            <input
              type="text"
              id="shift"
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            />
          </InputContainter>
          <InputContainter>
            <label htmlFor="startTime">Starttid:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </InputContainter>

          <InputContainter>
            <label htmlFor="endTime">Sluttid:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </InputContainter>

          <InputContainter>
            <label htmlFor="lunchTime">Lunch:</label>
            <input
              type="time"
              id="lunchTime"
              value={lunchTime}
              onChange={(e) => setLunchTime(e.target.value)}
            />
          </InputContainter>
          <InputContainter>
            <label htmlFor="button" className="hidden">
              button
            </label>
            <button
              type="submit"
              onClick={() => {
                handleAddShiftToColor();
              }}
            >
              Lägg till
            </button>
          </InputContainter>
        </AddShiftContainer>
      </ColorAndListContainer>
    </InnerWrapper>
  );
}
const InputContainter = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;
`;
const CategoryLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const ColorLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;
const ColorSwatch = styled.div`
  background-color: ${(props) => props.color};
  height: 30px;
  width: 50px;
  margin: 0 20px;
  border-radius: 10px;
`;
const AddShiftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  .hidden {
    visibility: hidden;
  }
  button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff; /* White text color by default */
    background-color: #007bff; /* Blue background color by default */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s; /* Transition both color and background-color */
    min-height: 44.2px;
    &:hover {
      background-color: #0056b3; /* Darker blue background color on hover */
    }

    &:focus {
      outline: none; /* Remove outline on focus for better visual appearance */
    }
  }
  label {
    text-align: start;

    margin: 5px 0px;
  }
  input {
    padding: 10px 10px;
    margin-right: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 16px;
    outline: none; /* Remove default outline */
    cursor: pointer; /* Show pointer cursor on hover */
    min-width: 75px;
  }
`;
const DeleteButton = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #d9534f; /* Red color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c9302c; /* Darker red on hover */
  }

  &:focus {
    outline: none; /* Remove outline on focus for better visual appearance */
  }
`;

const StartTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;
  align-items: center;
  input {
    width: 100px;
  }
  span {
    margin-right: 20px;
    margin-left: 20px;
  }
`;
const ColorHeader = styled.h4`
  margin: 0%;
  padding: 0%;
  text-align: center;
`;
const ColorAndListContainer = styled.div``;

const InnerWrapper = styled.div`
  border: solid;
  display: block;
  grid-column-start: 2;
  border-radius: 15px;
  padding-top: 1em;
  background-color: #b0c4de;
  width: 100%;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */

  input {
    max-width: 100px;
    height: 25px;
    text-align: center;
  }
`;

const Container = styled.div`
  margin-top: 1vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-row-gap: 1%;
  font-size: 1em;
`;

const OuterWrapper = styled.div`
  width: 100%;
  display: block;
  background-color: darkgray;
`;

const ShiftContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
`;
const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const CustomTable = styled.table`
  align-self: center;
  margin: 10px;
  width: 90%;
  border-collapse: collapse;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  th {
    background-color: #007bff; /* Blue header background */
    color: #fff; /* White text color */
    padding: 10px;
    border: 1px solid #007bff; /* Blue border */
    font-weight: bold; /* Bold font weight for headers */
  }

  td {
    background-color: #fff; /* White cell background */
    color: #333; /* Dark text color */
    padding: 10px;
    border: 1px solid #ccc; /* Light gray border */
    font-size: 22px; /* Font size for cell content */
    font-weight: 600;
    text-align: center;
  }
`;
