import { fetchEventSource } from "@microsoft/fetch-event-source";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../api/axios";
import { useDataContext } from "../../context/DataProvider";
import { useSettingsContext } from "../../context/SettingsProvider";
import { useUpdateContext } from "../../context/UpdateProvider";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { generateSelectMenu } from "../../utility/generateSelectMenu";
import { getIndexOfTodaysDate } from "../../utility/getIndexOfTodaysDate";
import DynamicViewComponent from "../DynamicViewComponent";
import HeaderComponent from "../HeaderComponent";
import StyledRadioButton from "../StyledRadioButton";

import UpdateLogComponent from "../UpdateLogComponent";
export default function HomePageComponent({ url, header }) {
  const { updateLog, setUpdateLog } = useUpdateContext();
  const departmentUrl = url || "";
  const auth = useAuth();
  const { setSettingsList, setSelectOptions } = useSettingsContext();
  const [viewStart, setViewStart] = useState(0);
  const [viewEnd, setViewEnd] = useState(3);
  const [viewLength, setViewLength] = useState(4);
  const [interval, setInterval] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const { dataList, setDataList } = useDataContext();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const weekIncludesTodaysDate = dataList
    ? getIndexOfTodaysDate(dataList[0]?.shifts) !== -1
      ? getIndexOfTodaysDate(dataList[0]?.shifts)
      : 0
    : 0;
  const [update, setUpdate] = useState();
  const [sseOn, setSSEOn] = useState(
    localStorage.getItem("eventStream") || false
  );
  const [restartEventStream, setRestartEventStream] = useState(false);
  const applyTextSizes = () => {
    return interval === 6 ? "1em" : interval === 3 ? "1em" : "1em";
  };
  const getLeftOrRightSideOfDisplay = (department) => {
    if (dataList) {
      const sortedArray = dataList.filter((item) =>
        department.includes(item.department)
      );

      return sortedArray;
    }
  };
  const displayLeft = getLeftOrRightSideOfDisplay([
    "*Gods",
    "*Hus 52",
    "*Jit",
    "*Ledning",
  ]);
  const displayRight = getLeftOrRightSideOfDisplay([
    "*Textil",
    "*Extra",
    "*Helg",
  ]);
  function nextPage(e) {
    e.preventDefault();
    if (viewEnd + interval <= 6) {
      let startValue = viewStart + interval;
      let endValue = viewEnd + interval;
      setViewStart(startValue);
      setViewEnd(endValue);
    }
  }
  function prevPage(e) {
    if (viewStart - interval >= 0) {
      e.preventDefault();
      let startValue = viewStart - interval;
      let endValue = viewEnd - interval;
      setViewStart(startValue);
      setViewEnd(endValue);
    }
  }
  function getDateComponent() {
    const dateArray = dataList[0];
    dateArray?.shifts.forEach((week) => {
      week.shifts.forEach((element) => {
        const date = new Date(element.date);
        element.shift = date.getDate();
      });
    });

    return (
      <section>
        <DynamicViewComponent
          includeMonth={true}
          name={""}
          disableEdit={true}
          sixWeekData={dateArray?.shifts}
          viewStart={viewStart}
          viewEnd={viewEnd}
          viewLength={viewLength}
          containerHeight={"17px"}
          changeTextSize={() => {
            return interval === 6 ? "1em" : interval === 3 ? ".75em" : "1em";
          }}
        ></DynamicViewComponent>
        {dateArray && (
          <DynamicViewComponent
            name={"NAMN"}
            disableEdit={true}
            sixWeekData={dateArray?.shifts}
            useData={"weekDay"}
            viewStart={viewStart}
            viewEnd={viewEnd}
            viewLength={viewLength}
            containerHeight={"17px"}
            changeTextSize={() => {
              return applyTextSizes();
            }}
          ></DynamicViewComponent>
        )}
      </section>
    );
  }
  function AddDateHeaders(index = 0) {
    const indexArray = [0, 8, 21, 33, 45];
    if (indexArray.includes(index)) {
      return (
        <Fragment key={"subheaders" + index}>
          {getDateComponent(index)}
        </Fragment>
      );
    }
  }

  const fetchSettingsData = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(`${departmentUrl}/settings`, {
        signal: controller.signal,
      });
      isMounted && setSettingsList(response.data);
      setSelectOptions(generateSelectMenu(response.data));
    } catch (err) {
      console.error(err);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchSettingsData();
  }, []);

  const fetchEmployeeData = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      setLoading(true);
      const response = await axiosPrivate.get(`${departmentUrl}/api`, {
        signal: controller.signal,
      });
      isMounted && setDataList(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setShowErrorMessage(true)
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchEmployeeData();
    getUpdates();
  }, []);

  useEffect(() => {
    const reloadWebpage = () => {
      window.location.reload();
    };
    setTimeout(reloadWebpage, 60000 * 60 * 24 + 10);
  }, []);

  useEffect(() => {
    const ctrl = new AbortController();
    let mount = true;
    let events;
    let timer;
    let pingCtrl = new AbortController();
    const pingServer = () => {
      const result = axiosPrivate
        .get("/ping", { withCredentials: true, signal: pingCtrl.signal })
        .catch((err) => {
          console.log(err);
          console.log("failed ping");
          pingCtrl.abort();
        });
      console.log(result);
    };
    let createEvents = async (events, timer) => {
      if (events) {
        ctrl.abort();
      }
      events = await fetchEventSource(`${BASE_URL}/${departmentUrl}/sse`, {
        method: "GET",
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${auth.auth.accessToken}`,
        },
        credentials: "include",
        heartbeatTimeout: 120000,

        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (res.status === 403) {
            console.log("Authorization expired!");
            ctrl.abort();
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          if (mount && event.event !== "ping" && event.data !== "") {
            console.log(event.event === "ping");
            console.log(event.data === "");
            console.log(event);
            const parsedData = JSON.parse(event.data);
            setUpdate(parsedData);
          }
          if (event.event === "ping") {
            console.log("Server ping!");
          }
          console.log(event);
        },
        onclose() {
          ctrl.abort();
          console.log("Connection closed by the server");
          setRestartEventStream(!restartEventStream);
        },
        onerror(err) {
          console.log("There was an error from server", err);
          ctrl.abort();
        },
      });
    };

    if (sseOn) {
      createEvents(events, timer);
      pingServer();
    }
    return () => {
      mount = false;
      clearTimeout(timer);
      ctrl.abort();
    };
  }, [sseOn, restartEventStream]);

  const getUpdates = async () => {
    try {
      await axiosPrivate
        .get(`${url}/api/update`, {
          headers: {
            "Context-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => setUpdateLog(response.data));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUpdates();
  }, [dataList]);

  useEffect(() => {
    if (viewEnd - viewStart === 1) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(1 + weekIncludesTodaysDate);
    }
    if (viewEnd - viewStart === 2 && weekIncludesTodaysDate >= 4) {
      setViewStart(4);
      setViewEnd(6);
    } else if (viewEnd - viewStart === 2) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(weekIncludesTodaysDate + 2);
    }

    if (viewEnd - viewStart === 3 && weekIncludesTodaysDate > 3) {
      setViewStart(3);
      setViewEnd(6);
    } else if (viewEnd - viewStart === 3) {
      setViewStart(weekIncludesTodaysDate);
      setViewEnd(weekIncludesTodaysDate + 3);
    }
  }, [weekIncludesTodaysDate]);

  useEffect(() => {
    if (update && dataList) {
      const documentIndex = dataList.findIndex(
        (item) => item?._id === update?.documentKey?._id
      );

      const weekIndex = Object.keys(Object.entries(update)[1][1])[0];
      const dataListWeekCopy =
        dataList[documentIndex]?.shifts[weekIndex.slice(-1)]?.shifts;
      const updateWeekCopy = update?.updatedFields[weekIndex]?.shifts;
      const result = dataListWeekCopy?.filter(
        (item, index) => item.shift !== updateWeekCopy[index].shift
      );
      const dayIndex = dataListWeekCopy?.findIndex(
        (item) => item?._id === result[0]?._id
      );

      const updatedDataList = [...dataList];
      if (dayIndex !== -1) {
        updatedDataList[documentIndex].shifts[weekIndex.slice(-1)].shifts[
          dayIndex
        ].shift = updateWeekCopy[dayIndex].shift;
        setDataList(updatedDataList);
      }
    }
  }, [update]);

  let currentSubHeader = "";
  function checkSubHeaders(currentSubHeader, department) {
    return currentSubHeader !== department;
  }

  return (
    <Container>
      <HeaderComponent
        header={header}
        url={departmentUrl}
        eventstream={sseOn}
        addPadding={false}
        //disableImport={true}
        setEventStream={setSSEOn}
      ></HeaderComponent>
      <ViewButtonCountainer>
        <ViewButtonContent>
          <NextButton onClick={prevPage}>Före</NextButton>
          <StyledRadioButton
            todaysDate={
              weekIncludesTodaysDate !== -1 ? weekIncludesTodaysDate : 0
            }
            disable={false}
            setStart={setViewStart}
            setEnd={setViewEnd}
            setLength={setViewLength}
            setInt={setInterval}
          ></StyledRadioButton>
          <NextButton onClick={nextPage}>Nästa</NextButton>
        </ViewButtonContent>
      </ViewButtonCountainer>
      {dataList && !loading && (
        <DisplayGrid>
          <DisplayContainer>
            {dataList && (
              <Fragment key={"initial0"}>{AddDateHeaders()}</Fragment>
            )}
            {dataList &&
              displayLeft.map((item, index) => {
                if (checkSubHeaders(currentSubHeader, item.department)) {
                  currentSubHeader = item.department;
                  return (
                    <Fragment key={index + 1000}>
                      <SectionHeader key={item.department}>
                        {item.department}
                      </SectionHeader>
                      <DynamicViewComponent
                        name={item.name}
                        key={item.name + index}
                        sixWeekData={item.shifts || []}
                        viewStart={viewStart}
                        viewEnd={viewEnd}
                        viewLength={viewLength}
                        containerHeight={"20px"}
                        changeTextSize={() => {
                          return applyTextSizes();
                        }}
                        url={departmentUrl}
                      ></DynamicViewComponent>
                    </Fragment>
                  );
                }
                return (
                  <DynamicViewComponent
                    name={item.name}
                    key={item.name + index}
                    sixWeekData={item.shifts || []}
                    viewStart={viewStart}
                    viewEnd={viewEnd}
                    viewLength={viewLength}
                    containerHeight={"20px"}
                    changeTextSize={() => {
                      return applyTextSizes();
                    }}
                    url={departmentUrl}
                  ></DynamicViewComponent>
                );
              })}
          </DisplayContainer>
          <DisplayContainer>
            {dataList && (
              <Fragment key={"initial0"}>{AddDateHeaders()}</Fragment>
            )}
            {dataList &&
              displayRight.map((item, index) => {
                if (checkSubHeaders(currentSubHeader, item.department)) {
                  currentSubHeader = item.department;
                  return (
                    <Fragment key={index + 1000}>
                      <SectionHeader key={item.department}>
                        {item.department}
                      </SectionHeader>
                      <DynamicViewComponent
                        name={item.name}
                        key={item.name + index}
                        sixWeekData={item.shifts || []}
                        viewStart={viewStart}
                        viewEnd={viewEnd}
                        viewLength={viewLength}
                        containerHeight={"20.5px"}
                        changeTextSize={() => {
                          return applyTextSizes();
                        }}
                        url={departmentUrl}
                      ></DynamicViewComponent>
                    </Fragment>
                  );
                }
                return (
                  <DynamicViewComponent
                    name={item.name}
                    key={item.name + index}
                    sixWeekData={item.shifts || []}
                    viewStart={viewStart}
                    viewEnd={viewEnd}
                    viewLength={viewLength}
                    containerHeight={"20.5px"}
                    changeTextSize={() => {
                      return applyTextSizes();
                    }}
                    url={departmentUrl}
                  ></DynamicViewComponent>
                );
              })}
            {dataList.length && <UpdateLogComponent context={updateLog} />}
          </DisplayContainer>
        </DisplayGrid>
      )}
      {!loading &&
        !dataList.length &&
        showErrorMessage&&
          <ErrorMessageContainer>
            <h2>Databasen är tom:</h2>
            <p>
              Om du ser detta meddelande är databasen för närvarande tom. Om
              detta har inträffat efter ett försök att ladda upp en excel-fil,
              är något i filen felaktigt implementerat eller startdatumet är
              inte en måndag. Kontrollera uppgifterna i excel-filen igen,
              bekräfta att startdatumet är en måndag och försök igen.
            </p>
          </ErrorMessageContainer>
        }
    </Container>
  );
}
export const swedishMonths = [
  "januari",
  "februari",
  "mars",
  "april",
  "maj",
  "juni",
  "juli",
  "augusti",
  "september",
  "oktober",
  "november",
  "december",
];
const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: whitesmoke;
  font-weight: 600;
  font-size: 2em;
  flex-direction: column;
  margin-left: 5%;
  p {
    width: 50%;
  }
`;
const Container = styled.div`
  background-color: #666e79;
  width: auto;
  padding: 0px;
  zoom: 58%;
`;
const DisplayContainer = styled.div`
  width: 50%;
  flex: 1;
`;
const ViewButtonContent = styled.div`
  display: flex;
  grid-column-start: 2;
  justify-content: center;
`;
const DisplayGrid = styled.div`
  display: flex;
  flex-direction: row;
`;
const SectionHeader = styled.div`
  margin-left: 110px;
  @media (max-width: 700px) {
    font-size: 1em;
  }
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  color: whitesmoke;
`;
const NextButton = styled.button`
  font-size: 1.2em;
  font-weight: 600;
  min-width: 110px;
  height: 30px;
  @media (min-width: 400px) {
    border: solid 2px;
    width: 10%;
  }
`;
const ViewButtonCountainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 6fr 3fr;
  grid-column-start: 2;
  justify-content: center;
  width: auto;
  margin: 10px;
  margin-left: 120px;

  align-items: center;
  button:hover {
    background-color: green;
    color: white;
    font-size: 1.2em;
  }
  @media (max-width: 700px) {
    margin: 5px 5px;
    button {
      width: 100%;
    }
  }
`;

export function getShiftOptions(settings) {
  const tempArray = [];
  settings.forEach((item, index) => {
    item.shift?.forEach((subItem) => {
      tempArray.push(subItem);
    });
  });
  return tempArray;
}
export function getSelectMenuOptions(settings) {
  const tempArray = [];
  settings.forEach((item, index) => {
    let category = item.category || undefined;
    item.shifts?.forEach((subItem) => {
      tempArray.push(category ? subItem.shift + `#${category}` : subItem.shift);
    });
  });
  return tempArray;
}
